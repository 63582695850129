<template>
  <div>
    <v-row class="mt-0 pt-0 px-5" justify="center">
      <v-col cols="12" class="py-0">
        <v-layout justify-center>
          <h2 class="color-text-main pt-4">LISTA DE COMISIONES</h2>
        </v-layout>
      </v-col>
      <v-col cols="12">
        <v-btn-toggle v-model="actionCommission" color="#E95233" group>
          <v-btn outlined value="active"> COMISIONES ACTUALES </v-btn>
          <v-btn outlined value="history"> HISTORIAL DE COMISIONES </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col class="pt-0 pb-0" :cols="colSearch">
        <v-text-field
          class="pt-5"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          clearable
          label="Buscar"
          placeholder="Ingrese aquí lo que desea buscar"
          color="#034f79"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col v-if="show.btn" class="pt-0 pb-0" cols="3">
        <v-layout justify-end>
          <v-btn
            :to="{
              name: 'commissionDetails',
              params: { commissionId: commissionId[0].id },
            }"
            block
            class="mt-5"
            color="#034f79"
            dark
          >
            DETALLES
          </v-btn>
        </v-layout>
      </v-col>
      <v-col class="py-0" cols="12">
        <v-divider class="my-2"></v-divider>
        <v-data-table
          v-model="commissionId"
          return-object
          :headers="headers"
          :items="items"
          single-select
          item-key="name"
          show-select
          :items-per-page.sync="itemsPerPage"
          :page.sync="page"
          :loading="loading.table"
          :search="search"
          loading-text="Cargando..."
          hide-default-footer
        >
          <!-- Sin resultados de busqueda -->
          <template v-slot:no-results>
            <v-alert icon="mdi-information-outline" prominent text type="info">
              <b>No se encontraron resultados con: {{ search }}</b>
            </v-alert>
          </template>
          <!-- Sin Data -->
          <template v-slot:no-data>
            <v-alert icon="mdi-information-outline" prominent text type="info">
              <b>No se encontró información.</b>
            </v-alert>
          </template>
          <!-- Loading -->
          <template v-slot:progress>
            <v-progress-linear
              color="#214484"
              :height="5"
              indeterminate
              stream
            ></v-progress-linear>
          </template>
          <!-- Status -->
          <template v-slot:item.statusVal="{ item }">
            <v-chip label dark small :color="getColorStatus(item.status_id)">
              {{ item.status.name }}
            </v-chip>
          </template>
          <!-- Actions -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  x-small
                  class="ml-3"
                  dark
                  color="#034f79"
                  :to="{
                    name: 'commissionDetails',
                    params: { commissionId: item.id },
                  }"
                >
                  <v-icon small>mdi-eye </v-icon>
                </v-btn>
              </template>
              <span>Detalles de la comisión</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.status_id != STATUS.completed"
                  v-bind="attrs"
                  v-on="on"
                  fab
                  x-small
                  class="ml-3"
                  dark
                  color="red"
                  @click="openModalDelete(item)"
                >
                  <v-icon small> mdi-trash-can-outline </v-icon>
                </v-btn>
              </template>
              <span>Eliminar la comisión</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="
                    item.status_id != STATUS.active &&
                    item.status_id != STATUS.completed &&
                    item.status_id != STATUS.cancel
                  "
                  v-bind="attrs"
                  v-on="on"
                  fab
                  x-small
                  class="ml-3"
                  dark
                  color="warning"
                  @click="openModalCancel(item)"
                >
                  <v-icon small> mdi-circle-off-outline </v-icon>
                </v-btn>
              </template>
              <span>Anular archivo de la comisión</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-divider class="my-2"></v-divider>
      </v-col>
      <v-col class="mt-2" cols="6">
        <v-layout justify-start>
          <span class="grey--text pr-4 pt-2"># de items por página</span>
          <v-select
            class="select-page"
            style="width: 80px"
            dense
            v-model="itemsPerPage"
            :items="itemsPerPageArray"
            label="# de items por página"
            solo
            color="#034f79"
          ></v-select>
        </v-layout>
      </v-col>
      <v-col class="mt-2" cols="6">
        <v-layout justify-end>
          <span class="mr-4 grey--text pt-1">
            Página {{ page }} de {{ numberOfPages }}
          </span>
          <v-btn
            x-small
            fab
            dark
            color="#E95233"
            class="mr-1"
            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            x-small
            fab
            dark
            color="#E95233"
            class="ml-1"
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-layout>
      </v-col>
    </v-row>

    <v-dialog v-model="modal.delete" max-width="500">
      <v-card>
        <v-card-title>
          <v-layout justify-center>
            <v-icon x-large color="warning"> mdi-alert </v-icon>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="pb-0 mb-2" style="font-size: 16px">
            <strong> ¿ Estás seguro de eliminar la comisión ? </strong>
          </p>
          <p class="pb-0 mb-0" style="font-size: 16px">
            Una vez eliminada la comisión no se puede reestablecer la
            información
          </p>
        </v-card-text>
        <v-card-actions>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="modal.delete = false"
                color="#c1c1c1"
                dark
                rounded
                block
              >
                Cerrar
              </v-btn>
            </v-layout>
          </v-col>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="deleteC()"
                color="red"
                dark
                rounded
                block
              >
                Eliminar
              </v-btn>
            </v-layout>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modal.cancel" max-width="500">
      <v-card>
        <v-card-title>
          <v-layout justify-center>
            <v-icon x-large color="warning"> mdi-alert </v-icon>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="pb-0 mb-2" style="font-size: 16px">
            <strong> ¿ Estás seguro de anular el archivo ? </strong>
          </p>
          <p class="pb-0 mb-0" style="font-size: 16px">
            Una vez se anule el archivo de la comisión, al momento de hacer el
            nuevo cargue del documento, se iniciará con el proceso de aprobación
            o rechazó desde 0
          </p>
        </v-card-text>
        <v-card-actions>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="modal.cancel = false"
                color="#c1c1c1"
                dark
                rounded
                block
              >
                Cerrar
              </v-btn>
            </v-layout>
          </v-col>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="cancel()"
                color="warning"
                dark
                rounded
                block
              >
                Anular archivo
              </v-btn>
            </v-layout>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import alert from "@/mixins/alert";
import color from "@/mixins/color";
import Api from "@/utils/api";
import { STATUS } from "./../../../utils/util";
export default {
  mixins: [alert, color],
  data() {
    return {
      STATUS: STATUS,
      actionCommission: "active",
      commissionId: [],
      modal: {
        cancel: false,
        delete: false,
      },
      comId: null,
      colSearch: 12,
      show: {
        btn: false,
      },
      loading: {
        table: false,
        btn: false,
      },
      itemsPerPageArray: [5, 10, 20, 50],
      search: "",
      page: 1,
      itemsPerPage: 5,
      headers: [
        {
          text: "Comisión",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Centro de costo",
          align: "start",
          sortable: false,
          value: "cost_center.name",
        },
        {
          text: "Campaña",
          align: "start",
          sortable: false,
          value: "campaign.name",
        },
        {
          text: "Cliente",
          align: "start",
          sortable: false,
          value: "client.name",
        },
        {
          text: "Porcentaje por estado",
          align: "start",
          sortable: false,
          value: "",
        },
        {
          text: "Estado",
          align: "start",
          sortable: false,
          value: "statusVal",
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      items: [],
    };
  },
  watch: {
    actionCommission: function (newA) {
      this.items = [];
      if (newA == "active") {
        this.getCommission();
      } else if (newA == "history") {
        this.getHistoryCompleted();
      }
    },
    commissionId: function (newId) {
      if (newId.length > 0) {
        this.show.btn = true;
        this.colSearch = 9;
      } else {
        this.show.btn = false;
        this.colSearch = 12;
      }
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },
  methods: {
    openModalCancel(item) {
      this.comId = item.id;
      this.modal.cancel = true;
    },
    openModalDelete(item) {
      this.comId = item.id;
      this.modal.delete = true;
    },
    cancel() {
      this.loading.btn = true;
      Api.Workforce()
        .getCommissionsCancel(this.comId)
        .then((res) => {
          if (res.data.cod == 0) {
            this.alert(res.data.message, "Muy bien", "success");
            this.modal.cancel = false;
            this.getCommission();
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.btn = false));
    },
    deleteC() {
      this.loading.btn = true;
      Api.Workforce()
        .getCommissionsDelete(this.comId)
        .then((res) => {
          if (res.data.cod == 0) {
            this.alert(res.data.message, "Muy bien", "success");
            this.modal.delete = false;
            this.getCommission();
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.btn = false));
    },
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
    getHistoryCompleted() {
      this.loading.table = true;
      Api.Auth()
        .getCommissionCompleted()
        .then((res) => {
          if (res.data.cod == 0) {
            this.items = res.data.data;
            this.alert(res.data.message, "Muy bien", "success");
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.table = false));
    },
    getCommission() {
      this.loading.table = true;
      Api.Auth()
        .getCommission()
        .then((res) => {
          if (res.data.cod == 0) {
            this.items = res.data.data;
            this.alert(res.data.message, "Muy bien", "success");
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.table = false));
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
  },
  mounted() {
    this.getCommission();
  },
};
</script>
<style>
.select-page .v-input__slot {
  width: 80px !important;
  box-shadow: none !important;
  border: 1px solid;
}
</style>
